$title: #32344f;
$light-colour: #a384ff;
$red: #ff6159;
$yellow: #ffbd2e;
$green: #29ce42;
$light-green: #93fe98;
$current-dir: #c0f7ff;
$term-font: 'Anonymous Pro', monospace;

#hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #fefefe;

  padding-top: 60px;
}

.hero-wrap {
  max-width: 1200px;
  width: 100vw;
  display: flex;
}

@media only screen and (max-width: 1200px) {
  .hero-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .hero-text {
    align-items: center;
  }

  .hero-img {
    justify-content: center !important;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .hero-text {
    font-size: 0.5em;
  }
  .hero-img {
    height: auto;
    width: auto;
    align-items: center;
    padding-top: 20px;
  }

  .img-box {
    min-width: auto;
    width: 400px !important;
  }

  .commands-cont {
    width: 100vw;
  }

  .six {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 320px) {
  .hero-text {
    font-size: 0.5em;
    align-items: center;
  }

  .six {
    flex-wrap: wrap;
  }

  .hero-img {
    height: auto;
    width: auto;
    align-items: center;
    padding-top: 20px;
  }

  .img-box {
    min-width: auto;
    width: 250px !important;
  }
}

.hero-text {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100vw;
}

.hero-img {
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-end;
  width: 100vw;
}

.title {
  display: flex;
  font-size: 6em;
  color: $title;
  padding: 0;

  &:after {
    content: '.';
    color: $light-colour;
  }
}

.img-box {
  transform: translatey(0px);
  width: 500px;
  max-width: 100vw;
  height: 300px;
  background: $title;
  border-radius: 10px;
}

.dots-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  height: 30px;
  background: #2c2f34;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .dots {
    margin: 3px;
    height: 10px;
    width: 10px;
    background: white;
    border-radius: 50%;
  }

  .red {
    background: $red;
  }

  .yellow {
    background: $yellow;
  }

  .green {
    background: $green;
  }
}

.commands {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: $term-font;
  letter-spacing: -0.5px;
}

.arrow {
  color: #93fe98;
  padding: 5px;
}

.current-dir {
  color: $current-dir;
  padding: 0 5px 3px 0;
}

.command-input {
  padding-bottom: 3px;
  letter-spacing: -0.5px;
}

.dir {
  color: $current-dir;
}

.six {
  padding-left: 13px;
}

.ls {
  padding-right: 20px;
}

.designer {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}

.developer {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.creator {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
