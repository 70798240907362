#about {
  display: flex;
  flex-direction: row;
  background-color: #a384ff;
  justify-content: center;
}

.about-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
}

.portrait {
  height: 250px;
  border: 5px solid white;
  border-radius: 50%;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: scale(1.03);
  }
}

.wave {
  position: relative;
  bottom: -10px;
  padding: 0;
  margin: 0;
}

.about-me {
  padding: 0 10px;
  max-width: 800px;
  width: 100%;
  margin-top: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  justify-content: center;
  p {
    padding: 10px 0;
  }
}

.about-title {
  padding: 30px 0;
  color: white;
  font-size: 3em;
  font-weight: bold;
}

.pg-link {
  margin-left: 5px;
  color: #32344f;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9em;
}

.rotated {
  top: -12px;
  position: relative;
  transform: scale(-1, -1);
}

@keyframes wobble {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.2;
  }
  100% {
    scale: 1;
  }
}
