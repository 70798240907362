$primary: #32344f;
$border: #e4e4e4;
$light-colour: #a384ff;

.nav-container {
  // padding: 10px 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  // border-bottom: 2px solid $border;
}

.nav-wrap {
  max-width: 1200px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .nav-wrap {
    padding: 10px 20px;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 700px) {
  .nav-wrap {
    padding: 0 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .nav-wrap {
    padding: 0 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav-li {
    font-size: 0.8em;
    padding: 0;
  }
}

@media only screen and (max-width: 380px) {
  .nav-container {
    padding: 0;
    flex-direction: column;
  }

  .nav-ul {
    font-size: 0.8em;
    width: 100vw;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .nav-li {
    padding: 0 !important;
  }

  .logo {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.nav-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-active {
  font-weight: bold;
}

.nav-li {
  text-decoration: none;
  padding: 15px;
  font-weight: bold;

  a,
  :visited,
  :link {
    font-size: 0.8em;
    text-transform: uppercase;
    text-decoration: none;
    color: $primary;
  }
}

.nav-li :hover {
  color: $light-colour;
  transition: 0.2s;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.05em;
  text-transform: uppercase;
  font-weight: bold;
  a {
    text-decoration: none;
    color: $primary;
  }
  // &:after {
  //   content: ".codes";
  //   color: $light-colour;
  // }
}

.codes {
  color: $light-colour;
}
