$title: #32344f;
$light-colour: #e6e6e6;

#tech {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid $light-colour;
}

@media only screen and (max-width: 480px) {
  .tech-type-cont {
    flex-direction: column !important;
  }

  .inner-cont {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .tech-name {
    margin: 10px;
  }

  .front-end-cont,
  .back-end-cont {
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .tech-type-cont {
    flex-direction: column !important;
  }

  .inner-cont {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .tech-name {
    margin: 10px;
  }

  .front-end-cont,
  .back-end-cont {
    padding-bottom: 10px;
  }
}

.comp-title {
  padding: 0 0 20px;
  color: $title;
  font-size: 3em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
}

.comp-p {
  text-align: center;
  padding: 10px 0;
  max-width: 800px;
  width: 100%;
}

.text-cont {
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tech-type-cont {
  margin-top: 70px;
  padding: 10px 10px 30px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;

  .tech-head {
    height: 30px;
    color: $title;
    width: 100%;
    display: flex;
    font-weight: bold;
    justify-content: center;
  }

  .tech-name {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 0 0 0;
  }
}

.icons {
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
