// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;700&family=Oswald:wght@300;400;700&family=Anonymous+Pro:wght@700&display=swap");

$main: "Monsterrat", sans-serif;
$heading: "Oswald", sans-serif;
$bg: #f7f7fc;
$primary: #858cb5;
$light-colour: #a384ff;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  font-family: $main;
  background: $bg;
  color: $primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
