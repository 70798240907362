$light-colour: #a384ff;
$title: #32344f;
$border: #e6e6e6;
$bg: #f7f7fc;

#projects {
  display: flex;
  width: 100vw;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 320px) {
  .project {
    width: 90vw;
  }

  .project-img img {
    width: 90vw;
    height: auto;
  }

  .overlay {
    height: 155px !important;
  }
}

.projects-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 70vw;
}

.comp-p {
  padding: 10px 0 20px;
}

.pro {
  margin-top: 50px;
}

.project {
  margin: 10px;
  background: $bg;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);

  &:hover .overlay {
    opacity: 0.7;
    height: 185px;
  }

  img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 350px;
  }
}

.icons-projects {
  margin-right: 10px;
}

.project-title {
  color: #32344f;
  display: flex;
  font-weight: bold;
  justify-content: center;
  padding: 5px;
  margin: 10px 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 185px;
  opacity: 0;
  transition: 0.3s ease;
  background-color: black;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: white;
    font-weight: bold;
    padding: 0 10px;
    text-align: justify;
  }
}

.tech-used-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 5px 0 10px;
  flex-grow: 1;
  padding-bottom: 50px;

  .lang-cont {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .lang-tag {
    display: flex;
    align-items: center;
    color: $title;
    padding: 10px;
  }
}

.project-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  width: 100%;
  padding: 10px;
  color: white;
  position: absolute;
  bottom: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  .git-link {
    color: white;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $light-colour;
      transition: 0.2s;
    }
  }
}
